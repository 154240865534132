import * as React from 'react';
import { graphql } from 'gatsby';
import { createGatsbyPageComponent } from '@shapeable/ui';
import { transformTopic } from '../../utils/transforms';
import { TopicExplorerLayout } from '../../components/entities/topic-explorer-layout';

export default createGatsbyPageComponent('Topic', { layout: TopicExplorerLayout, transform: transformTopic });

export const topicQuery = graphql`
  query TopicQuery($id: ID!) {
    platform {
      topic(id: $id) {
        id slug name path __typename
        outlineNumber
        trend { id path slug name __typename }
        description { text }
        intro { text }
        # openGraph { 
        #   title description { plain }
        #   image { url url2x thumbnails { card { url url2x } large { url url2x } } }
        # }
        pastYearAdvancements { text }
        anticipatoryImpact { text }
        indicatorValues {
          id value numericValue year
          indicator { id name slug dataSetId }
        }
        people {
          __typename id name slug position path
          photo { id url url2x }
          organisation { id name slug }
        }
        anticipatoryImpactImage { 
          image {
            id
            url
          }
        }
        embeds {
          citations {
            id slug url name authors { id name slug } 
            authorShowsEtAl edition publication accessDate 
            startPage volume footnoteNumber year
          }
          imageAssets {
            id
            slug
            image {
              id url url2x width height type 
            }
          }
        }
        video {
          id name url
          thumbnail { id url url2x }
        }
        videos {
          id name url
          thumbnail { id url url2x }
        }
        surveyObservations { text }
        color { id name slug value darkValue veryDarkValue }
        banner {
          id
          image {
            id
            url
            thumbnails { mainBanner { url url2x } }
          }
        }
        trend {
          id name slug __typename
          outlineNumber
          openGraph { image { thumbnails { card {url } } } }
          color { id name slug value }
        }
        chartImage { 
          id
          slug
          image { 
            id url url2x
          }
        }
        feedEntries {
          __typename _schema { label pluralLabel }
          id name slug __typename url published websiteName
          openGraph { image { url url2x thumbnails { card { url url2x } card { url url2x } } } }
          topic { id color { value } }
        }
        citations {
          __typename _schema { label pluralLabel }
          typeLabel
          slug url name authors { id name slug } 
          authorShowsEtAl edition publication accessDate 
          startPage volume footnoteNumber year
        }
        subTopics {
          id name path outlineNumber slug __typename 
          color { id name value }
          openGraph { id title image { id url url2x thumbnails { card { id url url2x } small { id url url2x } } } }
          indicatorValues {
            id value numericValue year
            indicator { id name slug dataSetId }
          }
        }
        committeeMembers {
          __typename id name slug position path
          badge
          topicExpertise {
            id name slug
            topic { id name slug path outlineNumber color { value } }
          }
          openGraph { image { id url url2x thumbnails { medium { url url2x } } } }
          organisation { id name slug }
          linkedin
          hasConnectedTopics
        }
        committeeChairs {
          __typename id name slug position path
          badge
          openGraph { id title image { id url url2x thumbnails { card { id url url2x } } } }
          organisation { id name slug }
        }
        connectedTrends {
          id name slug path outlineNumber color { value }
        }
        connectedTopics {
          id name slug path outlineNumber color { value }
        }
        connectedSubTopics {
          id name slug path outlineNumber color { value }
        }
        connectedPages {
          id name typeLabel slug path __typename type { name slug }
          trend { id name slug path outlineNumber color { value } }
          openGraph { image { url url2x thumbnails { card { url url2x } } } }
        }
      }
    }
  }
`;
